import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/datadog-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/components/v2/Toast/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.8_next@14.2.8_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plug_yxf4gieyo3uqsbv4g66c4pnjvu/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.8_next@14.2.8_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plug_yxf4gieyo3uqsbv4g66c4pnjvu/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.8_next@14.2.8_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plug_yxf4gieyo3uqsbv4g66c4pnjvu/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/app/node_modules/.pnpm/@tanstack+react-query-devtools@5.55.0_@tanstack+react-query@5.55.0_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_yhgtkmxmzhx37pfgsv2nurf6vy/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_yhgtkmxmzhx37pfgsv2nurf6vy/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/scalis-components/core/notification/notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.css");
